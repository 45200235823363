import { convertFromRaw } from 'draft-js'
import React, { useEffect, useState } from 'react'
import { Col } from 'reactstrap'

const ReportToCSV = ({report}) => {
    const [answerSessions, setAnswerSessions] = useState({})
    const [questionGroups, setQuestionGroups] = useState([])

    useEffect(()=>{
        if(report && report.questionGroups){
            try{
                let questionGroups = report.questionGroups
                let questionArray = questionGroups.map((group)=>{
                    let questionObject  =  group.questions.filter((question) => !question.isAttachmentQuestion && !question.isComment && !question.isNPS).map((question) => {
                        let returnObj = {}
                        if( question.isDateQuestion ){
                            report.dateQuestionAnswers[question.id] && report.dateQuestionAnswers[question.id].answers && report.dateQuestionAnswers[question.id].answers.map((answer)=>{
                                setAnswerSessions((prev)=>({
                                    ...prev,
                                    [answer.answerSessionId]: {
                                        ...prev[answer.answerSessionId],
                                        [question.id]: answer.text
                                    }
                                })
                                )
                            })
                            returnObj={ 
                                id:question.id,
                                type:'date',
                                text: question.text
                            }
                        }
                        else if ( question.isBusinessId ){
                            report.businessIdAnswers && report.businessIdAnswers.map(( business )=> {
                                business.answers.map((answer)=>{
                                    setAnswerSessions((prev)=>({
                                        ...prev,
                                        [answer.answerSessionId]: {
                                            ...prev[answer.answerSessionId],
                                            [question.id]:business.businessName + " - " + business.value
                                        }
                                    }))
                                })
                            })
                            returnObj={ 
                                id:question.id,
                                type:'businessId',
                                text: question.text
                            }
                        }
                        else if( question.isFreetextQuestion ){
                            question.freetextAnswers && question.freetextAnswers.map((answer)=>{
                                setAnswerSessions((prev)=>({
                                    ...prev,
                                    [answer.answerSessionId]: {
                                        ...prev[answer.answerSessionId],
                                        [question.id]: convertFromRaw(answer.text).getPlainText()
                                    }
                                }))  
                            })
                            returnObj={ 
                                id:question.id,
                                type:'text',
                                text: question.text
                            }
                        }
                        else if( question.isVariableQuestion ){
                            question.questionOptions.map((option)=>{
                                option.answerSessionIds && option.answerSessionIds.map((answerSessionId)=>{
                                    setAnswerSessions((prev)=>({
                                        ...prev,
                                        [answerSessionId]: {
                                            ...prev[answerSessionId],
                                            [question.id]: option.text
                                        }
                                    })
                                )})
                            })
                            returnObj={ 
                                id:question.id,
                                type:'variable',
                                text: question.text
                            }
                        }
                        else{
                            question.questionOptions.map((option)=>{
                                option.answerSessionIds && option.answerSessionIds.map((answerSessionId)=>{
                                    setAnswerSessions((prev)=>({
                                        ...prev,
                                        [answerSessionId]: {
                                            ...prev[answerSessionId],
                                            [question.id]: option.text
                                        }
                                    })
                                )})
                            })
                            returnObj={ 
                                id:question.id,
                                type:'multiselect',
                                text: question.text
                            }
                        }
                        return returnObj
                    })
                    
                    return {
                        groupName: group.title,
                        questions:questionObject
                    
                    }
                })
                setQuestionGroups([...questionArray])
            }catch(ex){ console.log(ex);
            }
        }
    },[report])
    const handleCsvDownload = () => {
       
       // Loop through the report and collect answers and questions to more readable state
        let csv = ""
        let headers = "SessionId;"
        let rows = ""
        questionGroups.map((group)=>{
            group.questions.map((question)=>{
                if(question.type === "businessId"){
                    headers+= "Yritys;"+"Y-tunnus;"
                }else{ 
                    headers+= question.text +";"
                }
               
            })
        })
        headers+="\n"

        Object.keys(answerSessions).map((key,index)=>{
            let row = ""
            row+=key+";"
            questionGroups.map((group)=>{
                group.questions.map((question)=>{
                    if(question.type === "businessId"){
                        //"Yritys;"+"Y-tunnus;"
                        let values = answerSessions[key][question.id].split(' - ')
                        if(values.length > 1){
                            row+= ((values[0]?values[0]+";": ";")+ (values[1]?values[1]+";": ";"))
                        } else {
                            row+= ";;"
                        }
                    }else{ 
                      row+= answerSessions[key][question.id]? answerSessions[key][question.id]+";":";"
                    }
                    
                })
            })
            rows+=row+"\n"
        })
        csv+=headers
        csv+=rows

        try{
            var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'text/csv;charset=windows-1252;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${report.title}_raportti`+".csv";
            
            document.body.appendChild(link);
    
            link.click();
    
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href)
        } catch {}
     }
    return(
        <Col style={{marginRight:'0.25rem', marginLeft:'auto'}}>
            <button className="general-button-report" onClick={() =>handleCsvDownload()}>
                Lataa CSV <i className="fas fa-download"></i>
            </button>
        </Col>
    )
}
export default ReportToCSV
